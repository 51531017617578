<script setup lang='ts'>
import { APP_ROUTER, BASE_HOST_APP } from '@qctsw/common'
import { isClient } from '@qctsw/utils'
import { getWxQrCode } from '~/server'

const { bus, hideLoginPopup, showLoginPopup } = useLoginPopupEvent()
const wxQrCode = ref('')
const showModal = ref(false)
const loginType = ref<'phone' | 'account'>('phone')
function busInitHandler() {
  bus.on(({ show, type }) => {
    showModal.value = show
    loginType.value = type

    const query = useRoute().query

    if (query.code)
      delete query.code

    const path = `${window.location.origin + window.location.pathname
    }${Object.keys(query).length ? '?' : `${new URLSearchParams(query as Record<string, string>).toString()}`}`
    if (show) {
      getWxQrCode({ redirectUrl: BASE_HOST_APP.includes('localhost') ? 'https://www.qctsw.com' : path }).then(({ data }) => {
        wxQrCode.value = data
      })
    }
  })
}

const nuxtApp = useNuxtApp()
nuxtApp.hook('page:finish', () => {
  if (isClient) {
    const route = useRoute()
    const showLogin = route.query?.showLogin as string | undefined
    if (showLogin) {
      const type = ['phone', 'account'].includes(showLogin) ? showLogin : 'phone'
      showLoginPopup(type as 'phone' | 'account')
    }
    else {
      hideLoginPopup()
    }
  }
})

const loginWxRef = ref()

busInitHandler()
</script>

<template>
  <n-modal v-model:show="showModal" mask-closable>
    <n-card size="huge" :bordered="false" role="dialog" aria-modal="true" class="w-110 rounded-md">
      <div class="flex items-center justify-around text-4.5 text-slate-4 mb-sm pb-sm border-b-1 border-slate-2 select-none">
        <div v-if="loginType === 'phone' " class="cursor-pointer" :class="{ active: loginType === 'phone' }" @click="showLoginPopup('phone')">
          手机号登陆
        </div>
        <div v-if="loginType === 'account' " class="cursor-pointer" :class="{ active: loginType === 'account' }" @click="showLoginPopup('account')">
          账号登录
        </div>
      </div>
      <div v-if="loginType === 'phone'">
        <LayoutLoginPhone />
      </div>
      <div v-else-if="loginType === 'account'">
        <LayoutLoginAccount />
      </div>

      <div class="flex items-center gap-5 mt-5 justify-center">
        <div v-if="loginType === 'account'" @click="loginType = 'phone'">
          <div class="w-12 h-12 rounded-full flex items-center justify-center bg-gray-100 color-#3b82f6 text-6 cursor-pointer">
            <span class="i-ant-design:mobile-filled" />
          </div>
          手机登录
        </div>
        <div v-if="loginType === 'phone'" @click="loginType = 'account'">
          <div class="w-12 h-12 rounded-full flex items-center justify-center bg-gray-100 text-6 cursor-pointer">
            <span class="i-material-symbols:lock" />
          </div>
          账号登录
        </div>
        <div @click="loginWxRef?.init(), showModal = false">
          <div class="w-12 h-12 rounded-full flex items-center justify-center bg-gray-100 text-6 cursor-pointer">
            <span class="i-bi:wechat color-#22c55e" />
          </div>
          微信登录
        </div>
      </div>
      <div class="flex items-center justify-end mt-sm">
        <NuxtLink :to="APP_ROUTER.forget()" class="hover:text-blue-5">
          忘记密码
        </NuxtLink>
        <template v-if="!$route.query.isSite">
          <n-divider vertical />
          <NuxtLink :to="APP_ROUTER.register()" class="hover:text-blue-5">
            注册
          </NuxtLink>
        </template>
      </div>
      <div class="flex items-center justify-center mt-xl text-3">
        登录/注册即代表同意
        <NuxtLink :to="APP_ROUTER.helpDetail(!!$route.query.isSite ? 29 : 5)" class="text-blue-5">
          《会员注册协议》
        </NuxtLink>
      </div>
    </n-card>
  </n-modal>
  <LayoutLoginWx ref="loginWxRef" :redirect-url="wxQrCode" />
</template>

<style lang='scss' scoped>
.active {
  --uno: font-bold text-slate-9;
}
</style>
