<script lang='ts' setup>
withDefaults(defineProps<{
  redirectUrl: string
}>(), {})
const showModal = ref(false)

function init() {
  showModal.value = true
}

defineExpose({ init })
</script>

<template>
  <n-modal v-model:show="showModal" mask-closable>
    <n-card size="huge" :bordered="false" role="dialog" aria-modal="true" style="--n-padding-left: 0" class="w-80 rounded-md">
      <iframe
        class="w-80 h-100"
        :src="redirectUrl"
      />
    </n-card>
  </n-modal>
</template>

<style lang='scss' scoped>
:deep(.n-card){
  .n-card__content, .n-card__footer{
    padding: 0;
  }
}
</style>
